import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {SharedModuleModule} from '../../shared-module/shared-module.module';
import {RouterModule, Routes} from '@angular/router';
import {PaymentPage} from './payment.page';

const routes: Routes = [
	{
		path: '',
		component: PaymentPage
	}
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModuleModule],
	declarations: [PaymentPage]
})
export class PaymentPageModule {}
